<template>
    <!-- <div style="height: auto; max-height: 244px"> -->
    <div class="row justify-center font-20 font-medium mb-2">
        Danh sách vé chuyển đổi lệnh
    </div>
    <DxList
        ref="DanhSachHanhKhachMuaVe"
        :dataSource="{
            store: DanhSachHanhKhachMuaVe,
            paginate: false,
        }"
        height="288px"
        keyExpr="idDonHang"
        :selectAllText="'Tất cả (' + DanhSachHanhKhachMuaVe.length + ')'"
        item-template="list-item"
        selectAllMode="allPages"
        selectionMode="all"
        :onSelectionChanged="onSelectionChanged"
        :showSelectionControls="true"
    >
        <template #list-item="{ data, index }">
            <div
                class="font-14 row align-center"
                style="border-bottom: 1px solid rgb(218, 220, 224)"
            >
                <div class="xs12">
                    <div :class="rowText">
                        <div class="xs5">Số điện thoại:</div>
                        <div :class="textRight" class="color-success">
                            {{ $Helper.formatPhone(data.soDienThoai) }}
                        </div>
                    </div>
                    <div :class="rowText">
                        <div class="xs5">Giá vé:</div>
                        <div :class="textRight" class="color-error">
                            {{ $MoneyFormat(data.thanhTien) }}đ
                        </div>
                    </div>
                    <div class="row">
                        <div class="xs5">Điểm xuống:</div>
                        <div :class="textRight" class="color-warning">
                            {{ data.tenDiemXuong }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DxList>
</template>

<script>
import { DxList } from "devextreme-vue";
export default {
    components: {
        DxList,
    },
    props: {
        DanhSachHanhKhachMuaVe: { type: Array, default: () => [] },
        DanhSachDonHang: { type: Array, default: () => [] },
        guidChuyenDi: { type: String, default: null },
    },
    data() {
        return {
            rowText: "row mb-1",
            textRight: "xs7 font-medium",
        };
    },
    methods: {
        onSelectionChanged(item) {
            let arrTmp = this.$refs.DanhSachHanhKhachMuaVe.instance.option(
                "selectedItemKeys",
            );
            this.$emit("update:DanhSachDonHang", arrTmp);
        },
        GioXuatBen(date) {
            if (date) {
                let tmpDate = new Date(date);
                let hours =
                    tmpDate.getHours() < 10
                        ? "0" + tmpDate.getHours()
                        : tmpDate.getHours();
                let minutes =
                    tmpDate.getMinutes() < 10
                        ? "0" + tmpDate.getMinutes()
                        : tmpDate.getMinutes();
                let tmpStr = hours + ":" + minutes;
                return tmpStr;
            }
            return "";
        },
    },
};
</script>

<style scoped>
.no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
